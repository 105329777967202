<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    authority="General_Topic"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './storeModule'

export default {
  components: {
    EntityList,
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'topics',
        endpoint: 'topics',
        route: 'topics',
        title: {
          single: this.$i18n.t('Topic'),
          plural: this.$i18n.t('Topics'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
        },
        {
          id: 'description',
          type: 'textarea',
          label: this.$t('description'),
        },
      ]
    },
  },
}
</script>
